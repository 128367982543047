import React from 'react';
import PropTypes from 'prop-types';

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    {/* <div className="logo">
      <span className="icon fa-diamond"></span>
</div> */}

    <div className="content">
      <div className="inner">
        <h1 class="responsive-headline">Hello </h1>
        <h1 class="responsive-headline">
          I'm <a href="https://geekyants.com/rishabh-pandey">Rishabh Pandey </a>
          .
        </h1>
        <h3>
          I'm Bangalore,India based <span>Full Stack JavaScript Developer</span>
          ,currently working as{' '}
          <span>
            Senior Software Engineer at{' '}
            <a href="https://geekyants.com/">GeekyAnts</a> .
          </span>
          I have keen interest in mobile & Web development , IOT , Ethical
          Hacking and much more.
        </h3>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('about');
            }}
          >
            About
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('skills');
            }}
          >
            Skills
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('work');
            }}
          >
            Work
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('resume');
            }}
          >
            Resume
          </a>
        </li>

        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('contact');
            }}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
};

export default Header;
