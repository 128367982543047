import React from "react";
import PropTypes from "prop-types";

import pic01 from "../images/pic01.jpg";
import pic02 from "../images/pic02.jpg";
import pic03 from "../images/pic03.jpg";
import rishabh from "../images/rishabh.jpg";
import rishabh1 from "../images/rishabh1.jpg";

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle();
        }}
      ></div>
    );

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}
      >
        <article
          id="about"
          className={`${this.props.article === "about" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={rishabh1} alt="Profile Pic" />
          </span>
          <p>
            Hey ! I am Rishabh Pandey,a person who is passionate about
            technologies,coding,taking up challenges and much more. I have keen
            interest in Software and Mobile/Web develpoment along with interest
            in various technical activities.
            <br />
            <strong>Qualities:</strong>
            Strong of mind, free, organizing skilled,self initiator,
            motivated,inventive, persuading charming and shrewd in business. I
            am talkative and cheerful, adapt very well to situations ,excessive
            and enthusiastic.
          </p>
          {close}
        </article>

        <article
          id="skills"
          className={`${this.props.article === "skills" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Skills</h2>
          <div class="row skill">
            <div class="nine columns main-col">
              <p>
                To succeed in this high-performance, global economy, everyone
                needs to understand and be good at communicating about skills
                that they hold and how much they are good at that particular
                skill set. So enlisted below are some of my Technical skills
                ,please have a Look .
              </p>

              <div class="bars">
                <ul class="skills">
                  <li>
                    <span class="bar-expand javascript"></span>
                    <em>Javascript</em>
                  </li>
                  <li>
                    <span class="bar-expand node"></span>
                    <em>Nodejs</em>
                  </li>
                  <li>
                    <span class="bar-expand expressjs"></span>
                    <em>ExpressJs</em>
                  </li>
                  <li>
                    <span class="bar-expand reactnative"></span>
                    <em>React & React Native</em>
                  </li>

                  <li>
                    <span class="bar-expand statemanagement"></span>
                    <em>State Management(Redux & Mobx)</em>
                  </li>
                  <li>
                    <span class="bar-expand nosql"></span>
                    <em>NoSQL(MongoDb, Dymanodb)</em>
                  </li>
                  <li>
                    <span class="bar-expand sql"></span>
                    <em>MySQL</em>
                  </li>

                  <li>
                    <span class="bar-expand socket"></span>
                    <em>Socket.io</em>
                  </li>
                  <li>
                    <span class="bar-expand php"></span>
                    <em>GraphQL</em>
                  </li>
                  <li>
                    <span class="bar-expand sql"></span>
                    <em>AWS EC2 , Lambda</em>
                  </li>
                  <li>
                    <span class="bar-expand php"></span>
                    <em>PHP</em>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === "work" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Work</h2>
          {/*<span className="image main">
            <img src={pic02} alt="" />
        </span>*/}
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9">
              <div class="text-wrap">
                <h4>App to track the market price of metals, batteries</h4>
                <p>
                  A mobile app using React Native, redux-thunk that is used to
                  fetch a daily market price for metals, stock available, buy
                  and sell price.
                </p>
              </div>
              <div class="text-wrap">
                <h4>
                  A Multi Tenant application for organisation management and
                  communications
                </h4>
                <p>
                  Portal to manage companies progress contribution and all based
                  on team, end-user. It is built with MYSQL, Expressjs, &amp;
                  React
                </p>
              </div>
              <div class="text-wrap">
                <h4>Expense Tracking App</h4>
                <p>
                  Banking API to financial analytics to build software that
                  makes saving money easy. It is built on Expressjs, GraphQL,
                  MySQL.
                </p>
              </div>
              <div class="text-wrap">
                <h4>Travel Planning app</h4>
                <p>
                  This app helps to plan the travel itinerary. It gives
                  information about popular destinations and the things to do
                  there. Also, it helps to plan the travel with your friends so
                  that the trip plan can be shared and edited on groups. It also
                  has a section for famous travel bloggers. Tech stack used is
                  React Native, AWS AppSync, Redux, Apollo GraphQL
                </p>
              </div>
              <div class="text-wrap">
                <h4>
                  An App To Improve Effectiveness of HealthCare Organisation
                </h4>
                <p>
                  Organisation Management API’s that keep track of
                  patient-focused initiatives by individual team, the
                  contribution of an individual with each organization having
                  some objective to achieve using Express.js, MySQL.
                </p>
              </div>
              <div class="text-wrap">
                <h4>
                  <a
                    href="https://market.nativebase.io/view/react-native-taxi-app-with-backend"
                    target="_blank"
                  >
                    Taxi App
                  </a>
                </h4>
                <p>
                  Developed the admin dashboard with overall monitoring &amp;
                  controlling of the Taxi App providing an easy interface to
                  manage CURD request for both users and ride using ReactJS,
                  Redux, Express, NodeJS. With both frontend &amp; backend
                  development, incorporated integration of API with UI designs
                  along with data management. Developed CURD API. Handle Live
                  sharing of the location using SocketIO
                </p>
              </div>
              <div class="text-wrap">
                <h4>
                  <a
                    href="https://market.nativebase.io/view/react-native-dating-app-with-backend"
                    target="_blank"
                  >
                    Dating App
                  </a>
                </h4>
                <p>
                  Developed the mobile app and backend with overall monitoring
                  &amp; providing an easy interface to make create match, and
                  message using React Native, Redux, GraphQL, GraphCool. With
                  both frontend &amp; backend development, incorporated
                  integration of API with UI designs along with data management.
                  Developed CURD API.
                </p>
              </div>
              <div class="text-wrap">
                <h4>
                  <a
                    href="https://market.nativebase.io/view/react-native-taskman-app-with-backend"
                    target="_blank"
                  >
                    TaskMan
                  </a>
                </h4>
                <p>
                  An ideal starter kit/app script with all the needed UI
                  elements and API built using NativeBase, Redux, Firebase to
                  build your iOS and Android TaskMan / service provider app just
                  like UrbanClap, TaskRabbit, Takl.
                </p>
              </div>
              <div class="text-wrap">
                <h4>Server Monitoring Application</h4>
                <p>
                  Server Monitoring Sofware, it monitors over 150 servers and
                  display their performance.
                </p>
              </div>
            </div>
          </div>
          {/*      <p>
            Adipiscing magna sed dolor elit. Praesent eleifend dignissim arcu,
            at eleifend sapien imperdiet ac. Aliquam erat volutpat. Praesent
            urna nisi, fringila lorem et vehicula lacinia quam. Integer
            sollicitudin mauris nec lorem luctus ultrices.
          </p>
          <p>
            Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
            libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
            Pellentesque condimentum sem. In efficitur ligula tate urna.
            Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor.
            Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
            libero. Mauris aliquet magna magna sed nunc rhoncus amet feugiat
            tempus.
   </p> */}
          {close}
        </article>

        <article
          id="resume"
          className={`${this.props.article === "resume" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Resume</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
        </span> */}
          <div class="columns download">
            <p>
              <a href="../../static/include/Rishabh-Resume.pdf" class="button">
                <i class="fa fa-download"></i>Download Resume
              </a>
            </p>
          </div>
          {/*   <div class="columns download">
            <p>
              <a
                href="../assets/include/Rishabh-coverLetter.pdf"
                class="button"
              >
                <i class="fa fa-download"></i>Download Cover Letter
              </a>
            </p>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent
            eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam
            erat volutpat. Praesent urna nisi, fringila lorem et vehicula
            lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.
            Aliquam libero et malesuada fames ac ante ipsum primis in faucibus.
            Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit
            amet.
     </p> */}
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === "contact" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Contact</h2>
          <div class="ten columns">
            <p class="lead">
              To begin with it will indeed be a pleasure of being connected with
              you through mail, call or social network and hope this association
              proves to be beneficial for both parties. Keep in Touch. I'm
              available for Freelance work, Consultant .
            </p>
          </div>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="tel:+919415452773" className="icon fa-phone">
                <span className="label">Mobile</span>
              </a>
            </li>
            <li>
              <a href="skype:+919415452773" className="icon fa-skype">
                <span className="label">Skype</span>
              </a>
            </li>
            <li>
              <a
                href="https://in.linkedin.com/in/pandeyrishabh25"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://wa.me/919415452773" className="icon fa-whatsapp">
                <span className="label">Whatsapp</span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/geekrishabh"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/GeekRishabh"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired
};

export default Main;
