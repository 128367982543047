import React from 'react';
import PropTypes from 'prop-types';

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <ul class="copyright">
      <li>&copy; Copyright 2019 </li>
    </ul>
    <p className="copyright">Built By: Geekrishabh</p>
  </footer>
);

Footer.propTypes = {
  timeout: PropTypes.bool
};

export default Footer;
